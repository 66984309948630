export enum QUERY_SEARCH_TYPE {
  /* 裙型 */
  SKIRT_TYPE = '0',
  /* 设计元素 */
  DESIGN_ELEMENT = '1',
  /* 系列 */
  SERIES = '2',
  /* 设计裁剪 */
  DESIGN_TAILORING = '3',
  /* 店铺 */
  STORE_NAME = '4',
  /* 品牌 */
  BRAND_NAME = '5',
  /* 风格 */
  STYLE = '6',
  /* 分类 */
  CATEGORY = '7',
  /* 渠道 */
  CHANNLE_SOURCE = '9',
  /* 店铺类型 */
  STORE_TYPE = '10',
}

// 渠道来源
export enum CHANNLE_SOURCE_ID_ENUM {
  /* 国内电商平台 */
  DOMESTIC_PLATFORM = '1',
  /* 国内独立站 */
  DOMESTIC_PRIVATE = '2',
  /* 国外独立站 */
  FOREIGN_PRIVATE = '3',
  /* 国外电商平台 */
  FOREIGN_PLATFORM = '4',
}

export const CHANNLE_SOURCE_ID_LIST = [
  { label: '国内电商平台', value: CHANNLE_SOURCE_ID_ENUM.DOMESTIC_PLATFORM },
  { label: '国内独立站', value: CHANNLE_SOURCE_ID_ENUM.DOMESTIC_PRIVATE },
  { label: '国外电商平台', value: CHANNLE_SOURCE_ID_ENUM.FOREIGN_PLATFORM },
  { label: '国外独立站', value: CHANNLE_SOURCE_ID_ENUM.FOREIGN_PRIVATE },
];

// 商品状态
export enum GOODS_STATE_ENUM {
  /* 在售 */
  IN_SALE = '1',
  /* 下架 */
  OFF_SALE = '2',
  /* 预售 */
  BOOKING = '3',
  /* 售罄 */
  SALE_OUT = '4',
}
export const GOODS_STATE_LIST = [
  { label: '在售', value: GOODS_STATE_ENUM.IN_SALE },
  { label: '预售', value: GOODS_STATE_ENUM.BOOKING },
  { label: '下架', value: GOODS_STATE_ENUM.OFF_SALE },
  { label: '售罄', value: GOODS_STATE_ENUM.SALE_OUT },
];

export enum GOODS_IS_NEW_ENUM {
  /* 是 */
  YES = '1',
  /* 否 */
  NO = '0',
}

export const GOODS_IS_NEW_LIST = [
  { label: '是', value: GOODS_IS_NEW_ENUM.YES },
  { label: '否', value: GOODS_IS_NEW_ENUM.NO },
];

export enum SORT_WORD_ENUM {
  /* 默认 */
  DEFAULT = '0',
  /* 上架日期 */
  IN_SALE_DATE = '1',
  /* 销售天数 */
  SALE_DAYS = '2',
}

export const SORT_WORD_LIST = [
  { value: SORT_WORD_ENUM.IN_SALE_DATE, label: '上架日期' },
  { value: SORT_WORD_ENUM.SALE_DAYS, label: '销售天数' },
];

export enum SORT_TYPE_ENUM {
  /* 正序 */
  TOP = '0',
  /* 倒序 */
  DOWN = '1',
}
