// 排序枚举
export enum SORT_ENUM {
  // 销量
  SALES = 'trdSalesSpuNumTd',
  // 销售额
  TURNOVER = 'trdSalesSpuAmtTd',
  // 上新商品数
  NUMBER_OF_NEW_PRODUCTS = 'itmShelfSpuCntFirst1d',
}

export enum TABS_ENUM {
  /* 全部店铺 */
  ALL = '0',
  /* 我的关注 */
  USER_FOCUS = '1',
  /* 团队关注 */
  GROUP_FOCUS = '2',
}

export const TABS_LIST = [
  { value: TABS_ENUM.ALL, label: '全部店铺' },
  { value: TABS_ENUM.USER_FOCUS, label: '我的关注' },
  { value: TABS_ENUM.GROUP_FOCUS, label: '团队关注' },
];
