
import { defineComponent, ref, computed } from 'vue';
import {
  SORT_ENUM,
  TABS_LIST,
  TABS_ENUM,
} from './constant';
import {
  ORDER_ENUM,
  FOUCE_STATUS_ENUM,
  ANALYSIS_STATUS_ENUM,
} from '@/modules/information-manage/shop-manage/constant';
import {
  getAllShopPage,
  getUserFocusShopPage,
  getGroupFocusShopPage,
  cancelFocusShop,
  setFocusShop,
  getGroupFocusShopCount,
  getUserFocusShopCount,
} from '@/modules/information-manage/shop-manage/api';
import { IGetAllShopReq, IAllShopPageItem } from '@/modules/information-manage/shop-manage/api/type.d';
import { useList } from '@/hooks/use-list';
import { useRouter } from 'vue-router';
import usePermissionConfig from '@/modules/information-manage/use-permission-config';
import { ElMessage } from 'element-plus';
import AddShopModal from './components/add-shop-modal/index.vue';
import { useAccountStore } from '@/store/account';
import { useDictionaryStore } from '@/store/dictionary';
import {
  CX_CUSTOM_DICTIONARY_KEY,
} from '@/constant/dictionary';
import { numberfuns } from 'cx-utils';
import NP from 'number-precision';

export default defineComponent({
  components: {
    AddShopModal,
  },
  setup() {
    const dictionaryStore = useDictionaryStore();
    dictionaryStore.getCxDictionary([
      CX_CUSTOM_DICTIONARY_KEY.CHANNLE_SOURCE,
      CX_CUSTOM_DICTIONARY_KEY.STORE_TYPE,
    ]);
    const groupFocusCount = ref(0);
    const userFocusCount = ref(0);
    const permissionConfig = usePermissionConfig();
    const addShopModalVisible = ref(false);
    const router = useRouter();
    const accountStore = useAccountStore();
    const currentTab = ref(TABS_ENUM.ALL);
    const {
      tableLoading,
      params,
      tableData,
      handleSearch,
      handleReset,
      tableTotal,
      handleSizeChange,
      handleCurrentChange,
      handleResetApi,
    } = useList<IAllShopPageItem, IGetAllShopReq>({
      request: {
        api: getAllShopPage,
        params: {
          prop: SORT_ENUM.SALES,
          order: ORDER_ENUM.DESC,
          storeName: '',
          channelTypeId: '',
          storeTypeId: '',
          pageNum: 1,
          pageSize: 10,
        },
      },
    });
    const sortList = ref([
      { label: '销量', value: SORT_ENUM.SALES, type: ORDER_ENUM.DESC },
      { label: '销售额', value: SORT_ENUM.TURNOVER, type: ORDER_ENUM.ASC },
      { label: '上新商品数', value: SORT_ENUM.NUMBER_OF_NEW_PRODUCTS, type: ORDER_ENUM.ASC },
    ]);
    // 修改sort时
    const selectSortIndex = ref(0);
    const handleChangeSortType = (index: number) => {
      const { type } = sortList.value[index];
      if (selectSortIndex.value !== index) sortList.value[selectSortIndex.value].type = ORDER_ENUM.ASC;
      sortList.value[index].type = type === ORDER_ENUM.DESC ? ORDER_ENUM.ASC : ORDER_ENUM.DESC;
      selectSortIndex.value = index;
      params.value.order = sortList.value[index].type;
      params.value.prop = sortList.value[index].value;
      handleSearch();
    };
    const handleChannelTypeChange = (val: any) => {
      params.value.channelTypeId = val;
      params.value.pageNum = 1;
      handleSearch();
    };
    const handleShopTypeChange = (val: any) => {
      params.value.storeTypeId = val;
      params.value.pageNum = 1;
      handleSearch();
    };
    const getGroupFocusCount = async () => {
      try {
        const { data } = await getGroupFocusShopCount();
        groupFocusCount.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    const getUserFocusCount = async () => {
      try {
        const { data } = await getUserFocusShopCount();
        userFocusCount.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    const beforeSearch = (index: number = 1) => {
      getGroupFocusCount();
      getUserFocusCount();
      handleSearch(index);
    };
    const handleRouteToTaobao = (row: IAllShopPageItem) => {
      const a = document.createElement('a');
      a.setAttribute('href', row.storeUrl);
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const handleRouteToShopAnalyse = (row: IAllShopPageItem) => {
      const { storeId, channelId } = row;
      router.push({
        name: 'InformationManageShopManageDetail',
        query: {
          storeId,
          channelId,
        },
      });
    };
    const handleFocusItem = async (row: IAllShopPageItem) => {
      try {
        switch (row.status) {
          case FOUCE_STATUS_ENUM.UN_FOUCE:
            await setFocusShop({
              channelId: row.channelId,
              storeId: row.storeId,
            });
            break;
          case FOUCE_STATUS_ENUM.FOUCE:
            await cancelFocusShop({
              channelId: row.channelId,
              storeId: row.storeId,
            });
            break;
          default:
            break;
        }
        ElMessage.success('操作成功');
        beforeSearch(params.value.pageNum);
      } catch (error) {
        console.log(error);
      }
    };
    const handlePressEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };
    const handleChangeTab = (val: TABS_ENUM) => {
      getGroupFocusCount();
      getUserFocusCount();
      switch (val) {
        case TABS_ENUM.ALL:
          handleResetApi(getAllShopPage);
          break;
        case TABS_ENUM.USER_FOCUS:
          handleResetApi(getUserFocusShopPage);
          break;
        case TABS_ENUM.GROUP_FOCUS:
          handleResetApi(getGroupFocusShopPage);
          break;
        default:
          break;
      }
    };
    const changeAddShopModalVisible = () => {
      addShopModalVisible.value = true;
    };
    const init = () => {
      beforeSearch();
    };
    const tabs = computed(() => TABS_LIST.map((v) => {
      switch (v.value) {
        case TABS_ENUM.GROUP_FOCUS:
          return {
            ...v,
            label: `${v.label}(${groupFocusCount.value})`,
          };
        case TABS_ENUM.USER_FOCUS:
          return {
            ...v,
            label: `${v.label}(${userFocusCount.value})`,
          };
        default:
          return {
            ...v,
            label: v.label,
          };
      }
    }));
    const CHANNLE_SOURCE_OPTIONS = computed(() => dictionaryStore
      .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.CHANNLE_SOURCE] || []);
    const STORE_TYPE_OPTIONS = computed(() => dictionaryStore
      .cxDictionaryMap[CX_CUSTOM_DICTIONARY_KEY.STORE_TYPE] || []);
    init();
    return {
      NP,
      CHANNLE_SOURCE_OPTIONS,
      STORE_TYPE_OPTIONS,
      TABS_ENUM,
      accountStore,
      ANALYSIS_STATUS_ENUM,
      changeAddShopModalVisible,
      addShopModalVisible,
      handleChangeTab,
      currentTab,
      tabs,
      handleFocusItem,
      FOUCE_STATUS_ENUM,
      permissionConfig,
      selectSortIndex,
      ORDER_ENUM,
      sortList,
      tableLoading,
      params,
      tableData,
      beforeSearch,
      handleReset,
      tableTotal,
      handleSizeChange,
      handleCurrentChange,
      handleChannelTypeChange,
      handleShopTypeChange,
      handleChangeSortType,
      handleRouteToTaobao,
      handleRouteToShopAnalyse,
      handlePressEnter,
      numberfuns,
    };
  },
});
