
import { defineComponent, ref } from 'vue';
import { ElForm, ElMessageBox } from 'element-plus';
import {
  collectStore,
} from '@/modules/information-manage/shop-manage/api';
import {
  ICollectStoreReq,
} from '@/modules/information-manage/shop-manage/api/type.d';
import { commonfuns, validatefuns } from 'cx-utils';

export default defineComponent({
  emits: ['update:visible'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formEl = ref<InstanceType<typeof ElForm>>();
    const formData = ref<ICollectStoreReq>({
      link: '',
    });
    const handleClose = async () => {
      await formEl.value?.resetFields();
      emit('update:visible', false);
    };
    const handleOpen = async () => {
    };
    const handleConfirm = async () => {
      try {
        await formEl.value?.validate();
        await collectStore(formData.value);
        ElMessageBox.alert('我们将尽快收录至系统中', '提交成功');
        handleClose();
      } catch (error) {
        console.log(error);
      }
    };
    return {
      formEl,
      formData,
      handleClose,
      handleOpen,
      handleConfirm,
      rules: {
        link: [{
          validator: (rules: any, value: string, cb: (err?:string) => void) => {
            if (commonfuns.isEmpty(value)) {
              cb('店铺链接不能为空');
            } else if (validatefuns.isURL(value)) {
              cb();
            } else {
              cb('格式错误');
            }
          },
        }],
      },
    };
  },
});
