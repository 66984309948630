import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import http from '@/core/http';
import { exportByBlob } from '@/core/utils/file-download';
import {
  QUERY_SEARCH_TYPE,
} from '../constant';
import {
  IGetStyleGoodsReq,
  IGetStyleGoodsRes,
  IGetSelectOptionsRes,
  IGetStyleGoodsInfoRes,
} from './type.d';

/**
 * 款式库 - 列表
 */
export function getStyleGoods(data: IGetStyleGoodsReq) {
  return http.post<IGetStyleGoodsRes>({
    url: '/moken-portal/web/v1/style-lib/style-info-page',
    // isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}
/**
 * 款式库 - 下拉选项
 */
export function getStyleGoodsInfo(data: {
  channelId: string;
  skc: string;
}) {
  return http.post<IGetStyleGoodsInfoRes>({
    url: '/moken-portal/web/v1/style-lib/find-one-by-id',
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 款式库 - 下拉选项
 */
export function getSelectOptions(data: {
  skcType: QUERY_SEARCH_TYPE;
}) {
  return http.post<IGetSelectOptionsRes[]>({
    url: `/moken-portal/web/v1/style-lib/style-skc-list/${data.skcType}`,
    isCancelDuplicateUrlRequests: true,
    server: DOMAIN_SYSTEM_ENUM.tiangong,
    loading: true,
    data,
  });
}

/**
 * 根据商品SKC下载图片
 */
export const downloadImageBySkc = (params: {
  skc: string;
  channelId: string;
  filename: string;
}) => exportByBlob({
  url: '/moken-portal/web/v1/style-lib/download-by-skc',
  loading: true,
  params,
  filename: params.filename,
});
