import { defineStore } from 'pinia';
import {
  getDictionaryFromCX,
} from '@/api/dictionary';
import { DictionaryState, IDictionaryItem, IFlatDictionaryItem } from './types';
import {
  DICTIONARY_STATUS_ENUM,
  CX_CUSTOM_DICTIONARY_KEY,
} from '@/constant/dictionary';
import { getSelectOptions } from '@/modules/resource-manage/style/api';
import { QUERY_SEARCH_TYPE } from '@/modules/resource-manage/style/constant';
import {
  getCategoryOptions,
  getAttributeOptions,
  getScDictOptions,
} from '@/modules/resource-manage/domestic-goods/api';
import { getEnumOptions } from '@/modules/information-manage/shop-manage/api';
import { QUERY_SEARCH_ATTRIBUTE } from '@/modules/resource-manage/domestic-goods/constant';
import { OPTION_ENUM } from '@/modules/information-manage/shop-manage/constant';

export const useDictionaryStore = defineStore({
  id: 'dictionary',
  state: (): DictionaryState => ({
    cxDictionaryMap: {},
  }),
  actions: {
    async getCxDictionary(codeList: string[], senderCode?: string) {
      try {
        const dictionaryKeys = Object.keys(this.cxDictionaryMap);
        let notIncludedCodeList = codeList.filter((key) => {
          return !dictionaryKeys.includes(key);
        });
        if (!notIncludedCodeList.length) return;
        const customKeys: string[] = [];
        const keys = Object.keys(CX_CUSTOM_DICTIONARY_KEY);
        notIncludedCodeList = notIncludedCodeList.filter((key) => {
          const flag = keys.includes(key);
          if (flag) {
            customKeys.push(key);
          }
          return !flag;
        });
        customKeys.forEach(async (key) => {
          let data:IDictionaryItem[] = [];
          let res;
          switch (key) {
            case CX_CUSTOM_DICTIONARY_KEY.SKIRT_TYPE:
            case CX_CUSTOM_DICTIONARY_KEY.DESIGN_ELEMENT:
            case CX_CUSTOM_DICTIONARY_KEY.SERIES:
            case CX_CUSTOM_DICTIONARY_KEY.DESIGN_TAILORING:
            case CX_CUSTOM_DICTIONARY_KEY.STORE_NAME:
            case CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME:
            case CX_CUSTOM_DICTIONARY_KEY.STYLE:
            case CX_CUSTOM_DICTIONARY_KEY.CHANNLE_SOURCE:
            case CX_CUSTOM_DICTIONARY_KEY.CATEGORY_SPU:
            case CX_CUSTOM_DICTIONARY_KEY.STORE_TYPE:
            case CX_CUSTOM_DICTIONARY_KEY.CATEGORY: {
              if (key === CX_CUSTOM_DICTIONARY_KEY.CATEGORY_SPU) {
                res = await getCategoryOptions();
              } else {
                res = await getSelectOptions({
                  skcType: QUERY_SEARCH_TYPE[key],
                });
              }
              const result:IDictionaryItem[] = []; // 存放结果集
              const itemMap: {
                [key: string]: IDictionaryItem;
              } = {};
              res.data.forEach((item) => {
                const id = item.valueCode;
                const pid = item.valueParentCode;
                if (!itemMap[id]) {
                  itemMap[id] = {
                    label: '',
                    value: '',
                    // children: [],
                    disabled: false,
                  };
                }
                const obj: IDictionaryItem = {
                  label: item.value,
                  value: item.valueCode,
                  disabled: false,
                };
                if (itemMap[id].children) {
                  obj.children = itemMap[id].children;
                }
                itemMap[id] = obj;
                const treeItem = itemMap[id];
                if (pid === '') {
                  result.push(treeItem);
                } else {
                  if (!itemMap[pid]) {
                    itemMap[pid] = {
                      label: '',
                      value: '',
                      disabled: false,
                    };
                  }
                  if (itemMap[pid]?.children) {
                    itemMap[pid].children?.push(treeItem);
                  } else {
                    itemMap[pid].children = [treeItem];
                  }
                }
              });
              data = result;
              break;
            }
            case CX_CUSTOM_DICTIONARY_KEY.STYLE1:
            case CX_CUSTOM_DICTIONARY_KEY.YEAR_SEASON:
            case CX_CUSTOM_DICTIONARY_KEY.CLOTHING_MODEL:
            case CX_CUSTOM_DICTIONARY_KEY.THICKNESS:
            case CX_CUSTOM_DICTIONARY_KEY.PATTERN:
            case CX_CUSTOM_DICTIONARY_KEY.FABRIC: {
              res = await getAttributeOptions({
                dictCode: QUERY_SEARCH_ATTRIBUTE[key],
              });
              data = res.data.map(item => ({
                label: item.name,
                value: item.value,
                disabled: false,
              })); // 存放结果集
              break;
            }
            case CX_CUSTOM_DICTIONARY_KEY.BRAND_NAME_DOMESTIC:
            case CX_CUSTOM_DICTIONARY_KEY.STORE_NAME_DOMESTIC: {
              res = await getScDictOptions({
                dictType: OPTION_ENUM[key],
              });
              data = res.data.map(item => ({
                label: item.dictValue,
                value: item.dictCode,
                disabled: false,
              })); // 存放结果集
              break;
            }
            case CX_CUSTOM_DICTIONARY_KEY.SECONDARY_CATEGORY:
              res = await getEnumOptions({
                dictType: OPTION_ENUM[key],
              });
              data = res.data.map(item => ({
                label: item.dictValue,
                value: item.dictCode,
                disabled: false,
              })); // 存放结果集
              break;
            default:
              break;
          }
          this.cxDictionaryMap[key] = data;
        });
        if (notIncludedCodeList.length === 0) return;
        const { data } = await getDictionaryFromCX({
          dictCodes: notIncludedCodeList,
          senderCode,
        });
        data.forEach((items) => {
          const result:IDictionaryItem[] = []; // 存放结果集
          const itemMap: {
            [key: string]: IDictionaryItem;
          } = {};
          items.dictValues.forEach((item) => {
            const id = item.valueCode;
            const pid = item.valueParentCode;
            if (!itemMap[id]) {
              itemMap[id] = {
                label: '',
                value: '',
                // children: [],
                disabled: false,
              };
            }
            const obj: IDictionaryItem = {
              label: item.value,
              value: item.valueCode,
              disabled: item.isEnable === DICTIONARY_STATUS_ENUM.DISABLE,
            };
            if (itemMap[id].children) {
              obj.children = itemMap[id].children;
            }
            itemMap[id] = obj;
            const treeItem = itemMap[id];
            if (pid === '') {
              result.push(treeItem);
            } else {
              if (!itemMap[pid]) {
                itemMap[pid] = {
                  label: '',
                  value: '',
                  disabled: false,
                };
              }
              if (itemMap[pid]?.children) {
                itemMap[pid].children?.push(treeItem);
              } else {
                itemMap[pid].children = [treeItem];
              }
            }
          });
          this.cxDictionaryMap[items.dictCode] = result;
        });
      } catch (e) {
        console.error(e, '获取字典数据接口异常');
      }
    },
    // 扁平化字典
    flatCxDictionary(code: string) {
      const deep = (
        arr: IDictionaryItem[],
        result: IFlatDictionaryItem[],
        pIds: string[],
        cIds: string[],
        lastLevelChildIds: string[],
      ) => {
        arr.forEach((v) => {
          const currentChildIds: string[] = [];
          const currentLastLevelChildIds: string[] = [];
          if (v.children && v.children.length) {
            deep(v.children, result, [...pIds, v.value], currentChildIds, currentLastLevelChildIds);
          } else {
            lastLevelChildIds.push(v.value);
          }
          cIds.push(v.value, ...currentChildIds);
          lastLevelChildIds.push(...currentLastLevelChildIds);
          result.push({
            ...v,
            pIds: [...pIds],
            cIds: [...currentChildIds],
            lastLevelChildIds: [...currentLastLevelChildIds],
          });
        });
      };
      if (!this.cxDictionaryMap[code]) return [];
      const result:IFlatDictionaryItem[] = [];
      deep(this.cxDictionaryMap[code], result, [], [], []);
      return result;
    },
  },
});
